import { z } from 'zod'

const BoardEvents = {
  YouTubeImportCompleted: 'board_youtube_import',
  PersonalizeAction: 'board_personalize_action',
  PersonalizeViewOriginalAction: 'board_personalize_view_orignal_action',
  ShareClicked: 'board_share_clicked',
  BoardSaved: 'board_saved',
  BoardViewed: 'board_viewed',
  PlanTrip: 'board_plan_trip',
} as const

export const BoardEventsEnum = z.nativeEnum(BoardEvents)
export type BoardEventsType = z.infer<typeof BoardEventsEnum>

export const BoardEventPayloadSchema = z.object({
  url: z.string(),
  authorId: z.string(),
  boardToken: z.string(),
})
export type BoardEventPayload = z.infer<typeof BoardEventPayloadSchema>

export const BoardSharedEventPayloadSchema = z.object({
  boardToken: z.string(),
  sharedTo: z.string(),
  sharedFrom: z.string(),
})
export type BoardSharedEventPayload = z.infer<typeof BoardSharedEventPayloadSchema>

export const PersonalizeEventPayloadSchema = z.object({
  newAuthorId: z.string(),
  newBoardToken: z.string(),
  sourceAuthorId: z.string(),
  sourceBoardToken: z.string(),
})

export type PersonalizeEventPayload = z.infer<typeof PersonalizeEventPayloadSchema>

export const BoardCommonPayloadSchema = z.object({
  boardToken: z.string(),
  boardPrice: z.number().nullish(),
  boardTitle: z.string(),
  authorName: z.string(),
  authorUid: z.string(),
  source: z.string(),
})

export type BoardCommonEventPayload = z.infer<typeof BoardCommonPayloadSchema>
